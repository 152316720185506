<template>
    <ion-grid :style="generatedStyles">
        <ion-row>
            <!--Вынес LABEL для мобилок из ion-item, чтобы вертикально элементы располагались на маленьких экранах-->
            <template v-if="$isMobile">
                <ion-label
                    v-if="hasError"
                    :position="position"
                    class="text-danger-label"
                    style="margin-left: 20px;"
                >
                    {{ label }}
                    <span
                        v-if="required"
                        class="text-danger"
                    >*
                    </span>
                </ion-label>
                <ion-label
                    v-else
                    :position="position"
                    class="label-default"
                    style="margin-left: 20px;"
                >
                    {{ label }}
                    <span
                        v-if="required"
                        class="text-danger"
                    >*
                    </span>
                </ion-label>
            </template>
            <!--Вынес LABEL для мобилок из ion-item, чтобы вертикально элементы располагались на маленьких экранах-->
            
            <ion-item style="width: 100%; min-height: 35px;">
                <!--А здесь LABEL для больших экранов-->
                <template v-if="!$isMobile">
                    <ion-label
                        v-if="hasError"
                        :position="position"
                        class="text-danger-label"
                    >
                        {{ label }}
                        <span
                            v-if="required"
                            class="text-danger"
                        >*
                        </span>
                    </ion-label>
                    <ion-label
                        v-else
                        :position="position"
                        class="label-default"
                    >
                        {{ label }}
                        <span
                            v-if="required"
                            class="text-danger"
                        >*
                        </span>
                    </ion-label>
                </template>
                <!--А здесь LABEL для больших экранов-->
                
                <ion-textarea
                    v-if="autoGrow"
                    ref="ionInput"
                    :maxlength="maxLength"
                    :placeholder="placeholder"
                    :required="required"
                    :value="modelValue"
                    auto-grow
                    rows="2"
                    @ionInput="updateValue"
                ></ion-textarea>
                <ion-input
                    v-else
                    ref="ionInput"
                    :pattern="pattern"
                    :placeholder="placeholder"
                    :required="required"
                    :type="type"
                    :value="modelValue"
                    @ionInput="updateValue"
                />
            </ion-item>
        </ion-row>
        
        <ion-row style="margin-left: 8px; padding: 0.1rem 0.5rem; font-size: 0.8rem;">
            <ion-col class="text-danger">
                {{ generatedTextError }}
            </ion-col>
            <ion-col
                v-if="autoGrow && maxLength && !hideLength"
                size="auto"
                style="color: #838383;"
            >
                {{ modelValue.toString().length }} / {{ maxLength }}
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import {
    computed,
    ref,
    getCurrentInstance,
    toRefs,
} from 'vue';

export default {
    name:  'InputDefault',
    props: {
        label: {
            type:    String,
            default: '',
        },
        
        placeholder: {
            type:    String,
            default: '',
        },
        
        type: {
            type:    String,
            default: 'text',
        },
        
        pattern: {
            type:    String,
            default: '',
        },
        
        required: {
            type:    Boolean,
            default: false,
        },
        
        inputNumbers: {
            type:    Boolean,
            default: false,
        },
        
        autoGrow: {
            type:    Boolean,
            default: false,
        },
        
        hideLength: {
            type:    Boolean,
            default: false,
        },
        
        maxLength: {
            type:    Number,
            default: 500,
        },

        position: {
          type: String,
          default: 'default',
        },
        
        rules: {
            type:    Array,
            default: () => [],
        },
        
        modelValue: {
            required: true,
        },
    },
    
    setup(props) {
        const { modelValue } = toRefs(props);
        
        const app = getCurrentInstance();
        const errorsArray = ref([]);
        const hasError = computed(() => errorsArray.value.length > 0 && errorsArray.value[0](modelValue.value) !== true);
        const labelClasses = computed(() => ({
            'danger': hasError.value,
        }));
        const generatedStyles = computed(() => {
            return app.appContext.config.globalProperties.$isMobile
                   ? {
                    'padding-left':  '0',
                    'padding-right': '0',
                    'margin-left':   '-15px',
                    'margin-right':  '0',
                }
                   : {};
        });
        
        const generatedTextError = computed(() => {
            if (hasError.value) {
                const res = errorsArray.value[0](modelValue.value);
                
                return res === true ? ' ' : res;
            }
            else {
                return ' ';
            }
        });
        
        return {
            errorsArray:        errorsArray,
            hasError:           hasError,
            labelClasses:       labelClasses,
            generatedStyles:    generatedStyles,
            generatedTextError: generatedTextError,
        };
    },
    
    methods: {
        checkValidValue(value = null) {
            return this.rules.filter(v => v(value || this.modelValue) != true); // либо true, либо сообщение об ошибке, поэтому такая проверка;
        },
        
        validate(value = null) {
            this.errorsArray = this.checkValidValue(value);
            return !this.hasError;
        },
        
        updateValue(val) {
            if (this.inputNumbers) {
                if (/,/.test(val.target.value)) {
                    val.target.value = val.target.value.replace(/,/, '.');
                }
                
                if (/\s/.test(val.target.value)) {
                    val.target.value = val.target.value.replace(/\s/, '');
                }
                
                if (isNaN(val.target.value)) {
                    val.target.value = val.target.value.replace(/[^.\d]/g, '');
                }
                
                // если 2 точки, 1 убираем
                if (/[.]$/g.test(val.target.value) && /[.]/g.test(val.target.value.slice(0, -1))) {
                    val.target.value = val.target.value.slice(0, -1);
                }
                
                this.$refs.ionInput.$el.lastChild.value = val.target.value;
            }
            
            this.$emit('update:modelValue', val.target.value);
            this.validate(val.target.value);
        },
    },
};
</script>

<style scoped>

</style>

<template>
    <div style="display:flex; justify-content: center; align-items: center; width: 100vw; height: 100vh;">
        <ion-card style="width: 400px;">
            <ion-card-header>
                <!--<ion-card-subtitle>Авторизация</ion-card-subtitle>-->
                <ion-card-title>Панель администратора</ion-card-title>
            </ion-card-header>
            
            <ion-card-content style="padding-bottom: 0;">
                <InputDefault
                    ref="loginInput"
                    :model-value="login"
                    :rules="[ v => !!v || 'Укажите логин' ]"
                    label="Логин"
                    required
                    @update:modelValue="val => updateLoginFormInputs(val, 'login')"
                    @keyup.enter="loginAction"
                />
                <InputDefault
                    ref="passwordInput"
                    :model-value="password"
                    :rules="[ v => !!v || 'Укажите пароль' ]"
                    label="Пароль"
                    required
                    type="password"
                    @update:modelValue="val => updateLoginFormInputs(val, 'password')"
                    @keyup.enter="loginAction"
                />
            </ion-card-content>
            
            <ion-row style="width: 100%;">
                <ion-button
                    style="width: 100%; margin: 1rem"
                    color="primary"
                    @click="loginAction"
                >
                    Войти
                </ion-button>
            </ion-row>
        </ion-card>
    </div>
</template>

<script>
import InputDefault from '../../components/InputDefault';
import { ref } from 'vue';
import { StorageKeys } from '../../scripts/storage_keys';

export default {
    name:       'AdminLogin',
    components: { InputDefault },
    
    setup() {
        const login = ref(null);
        const password = ref(null);
        
        return {
            login:    login,
            password: password,
        };
    },
    
    methods: {
        updateLoginFormInputs(val, formKeyName) {
            this[formKeyName] = val;
        },
        
        async loginAction() {
            try {
                const loginValidate = this.$refs.loginInput.validate();
                const passwordValidate = this.$refs.passwordInput.validate();
            
                if (loginValidate && passwordValidate) {
                    // const request = {
                    //     login:  this.login,
                    //     password:  this.password,
                    // };
                    // const response = await axios.post('/api/admin/login', request);
                    const response = { data: { data: { success: true } } };
                
                    if (response.data.data.success) {
                        localStorage.setItem(StorageKeys.TOKEN_DATA, JSON.stringify({ 1: { access: 'sadasdasd' } }));
                        localStorage.setItem(StorageKeys.USER_ROLE, 1);
                        this.$success('Авторизация прошла успешно. Сейчас вы будете перенаправлены в панель администратора.');
                        await new Promise(f => setTimeout(f, 1500));
                        location.reload();
                    }
                    else {
                        this.$error('Не удалось авторизоваться. Попробуйте позже или обратитесь к администратору.');
                    }
                }
            }
            catch (err) {
                console.error('ERROR login');
                console.error(err);
                this.$error('Произошла ошибка при авторизации. Попробуйте позже или обратитесь к администратору.');
            }
        },
    },
};
</script>

<style scoped>

</style>
